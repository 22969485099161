import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CouponsUpdatedService } from 'src/app/services/member/coupons-updated/coupons-updated.service';
import { CouponsService } from 'src/app/services/member/coupons/coupons.service';
import { RedemptionService } from 'src/app/services/member/redemption/redemption.service';
import { StorageService } from 'src/app/services/storage/storage.service';

@Component({
  selector: 'app-coupon-confirm-modal',
  templateUrl: './coupon-confirm-modal.component.html',
  styleUrls: ['./coupon-confirm-modal.component.scss'],
})
export class CouponConfirmModalComponent implements OnInit {
  @Input() section: string;
  @Input() json: any;
  @Input() code: string;
  @Input() couponSet: string;
  @Input() cQCcode: string;
  @Input() mQCcode: string;
  success: boolean;
  loading: boolean;
  callService: boolean;
  token: string;
  text: string;

  constructor(
    private modalCtrl: ModalController,
    private redemptionService: RedemptionService,
    private couponsUpdatedService: CouponsUpdatedService,
    private couponsService: CouponsService,
    private storageService: StorageService
  ) {
    this.success = false;
    this.loading = false;
    this.callService = false;
    this.storageService.getToken().then((token) => {
      this.token = token.value;
    });
  }

  ngOnInit() {
    if(this.section==='assign') {
      this.text = 'Are you sure you want to purchase the coupon?';
    } else {
      this.text = 'Are you sure you want to burn the coupon?';
    }
  }

  clickButton(action: boolean) {
    if (action && this.section==='assign') {
      this.loading = true;

      this.redemptionService.assignCoupon(this.json, this.cQCcode, this.mQCcode, this.token).subscribe((res: any) => {
        console.log(res.body.payload.data[0]);
        this.couponsUpdatedService.addCoupon(res.body.payload.data[0]);
        this.loading = false;
        this.callService = true;
        this.success = true;
        this.text = 'Successful coupon purchase!';

      }, (err) => {
        this.callService = true;
        this.loading = false;
        this.text = 'Something went wrong. Please try again later!';
      });
    } else if (action && this.section==='burn') {
      this.loading = true;

      this.couponsService.burnCoupon(this.json, this.code, this.couponSet, this.token).subscribe((res: any) => {
        console.log(res);
        this.loading = false;
        this.callService = true;
        this.success = true;
        this.text = 'Successful coupon purchase!';
      }, (err) => {
        if (err.error.messages[0].code === 1101) {
          this.text = 'Αδυναμία εξαργύρωσης κουπονιού. Επιτρέπεται η εξαργύρωση μονο 2 κουπονιών λιπαντικών Argo ανά ημέρα';
        } else {
          this.text = 'Something went wrong. Please try again later!';
        }
        console.log(err);
        this.callService = true;
        this.loading = false;
      });
    } else {
      this.success = false;
      this.modalCtrl.dismiss({
        success:  this.success
      });
    }
  }

  clickButtonSuccess() {
    this.success = true;
    this.modalCtrl.dismiss({
      success:  this.success
    });
  }

  clickButtonError() {
    this.success = false;
    this.modalCtrl.dismiss({
      success:  this.success
    });
  }

}
